/**
* @author zjc[beica1@outook.com]
* @date 2021/12/29 14:19
* @description
*   FillInUp.common.vue of WeTrade
*/

<template>
  <PageWithHeader class="fill-up">
    <template #title>
      <t path="personalinfo_9">Personal Information</t>
    </template>
    <div class="px-16">
      <t as="p" path="personalinfo_2" class="margin-top-16 font-14">
        The following information is required for the first deposit.
        Please double check and ensure that the input is correct.
      </t>
      <t custom #="{td, t}">
        <Form ref="form" :schema="schema" @submit="bind" #="{ values }" class="my-16">
          <FInput name="firstName" :placeholder="t('personalinfo_3') || 'First Name'"
            class="block" />
          <FInput v-if="!isEURCountry()" name="middleName" :placeholder="t('personalinfo_4') || 'Middle Name'"
            class="block" />
          <FInput name="lastName" :placeholder="t('personalinfo_5') || 'Last Name'" class="block" />
          <!--<FInput name="email" :placeholder="t('personalinfo_6') || 'Email'" class="block" />-->
          <TheEmailInput />
          <template v-if="noMobileBind">
            <TheMobileInput class="block px-8" />
            <TheCodeInput class="block px-8"
              @send="cb => sendCode(values.countryCode, values['mobile'], cb)" />
          </template>
        </Form>
      </t>
    </div>
    <template #footer>
      <Button class="block primary f-lg f-bold" @click="submit" :progress="progress">
        <t path="personalinfo_8" custom #="{td}">{{td || 'Submit'}}</t>
      </Button>
    </template>
  </PageWithHeader>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import { keymap } from '@/config'
import { isEURCountry } from '@/modules/app/app'
import TheEmailInput from '@/modules/in/components/TheEmailInput.vue'
import { getBindFormSchema, useBindPayer } from '@/modules/in/in'
import { sendBindCode } from '@/modules/user/user.api'
import TheMobileInput from '@/pages/login/components/TheMobileInput.vue'
import TheCodeInput from '@/pages/register/components/TheCodeInput.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { YesOrNo, Data } from '@/types'
import Form from 'common/form/Form.vue'
import FInput from 'common/form/inputs/FormInput.vue'
import { localGet } from 'essential/store/localStore'
import { goonTask, removeTask } from 'essential/task/task.temp'
import { defineComponent, onBeforeUnmount, ref } from 'vue'
import { useRoute } from 'vue-router'
import * as R from 'ramda'
import { showAlert } from '@/components/popup/popup'
import { translate } from '@/i18n'

export default defineComponent({
  name: 'FillInUpForCommon',
  components: { TheEmailInput, TheMobileInput, Button, Form, FInput, TheCodeInput, PageWithHeader },
  setup () {
    const noMobileBind = Number(localGet(keymap.user.mobileBindStatus)) !== YesOrNo.YES
    const { request, progress } = useBindPayer()
    const form = ref<HTMLFormElement | null>(null)
    const query = useRoute().query
    const taskId = query.pendingTaskId as string

    onBeforeUnmount(() => {
      removeTask(taskId)
    })

    return {
      isEURCountry,
      form,
      noMobileBind,
      schema: getBindFormSchema(noMobileBind),
      sendCode (cc: string, mobile: string, cb: () => void) {
        return sendBindCode(cc, mobile).then(() => {
          cb()
          showAlert(translate('toast_9', 'Code has been sent'))
        })
      },
      bind (data: Data) {
        const noBlankData = R.mapObjIndexed(R.trim, data as Record<string, string>)
        return request(noBlankData, noMobileBind).then(() => {
          if (taskId) {
            goonTask(taskId)
          }
        })
      },
      progress,
      submit () {
        (form.value as HTMLFormElement).submit()
      },
    }
  },
})
</script>
